// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import packageInfo from '../../package.json';

export const environment = {
  env: "qa",
  firebaseConfig: {
    apiKey: 'AIzaSyDG7bSFcHT6fhCHb7D8MMrJc0xHZX_JDv8',
    authDomain: 'brewngo-dev.firebaseapp.com',
    projectId: 'brewngo-dev',
    storageBucket: 'brewngo-dev.appspot.com',
    messagingSenderId: '195338918694',
    appId: '1:195338918694:web:79874e45f9c6b371a3bfe7',
  },
  // sentryConfig: {
  //   enabled: true,
  //   dsn: 'https://87db608cbb494339b7cda48c21f3895b@o4504482531442688.ingest.sentry.io/4504482535112704',
  //   release:  packageInfo.name,
  //   version:  packageInfo.version,
  //   url: 'dev.bngo.app',
  //   dist: 'qa',
  //   tracesSampleRate: 1.0,
  // },
  production: true,
  useEmulators: false,
  emulator_ip: "",
  googleMapsApiKey: "AIzaSyB3TY9kmomIHYEYOXurfYJkTzzKUQRss7w"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
