import { customClaims, emailVerified } from '@angular/fire/auth-guard';
import {
  CollectionReference,
  Query,
  collectionSnapshots,
  limit, orderBy,
  query,
  writeBatch
} from '@angular/fire/firestore';
import { format, utcToZonedTime } from 'date-fns-tz';
import { firstValueFrom, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfile } from '../interfaces/user-profile';

export const adminOnly = (next: any) =>
  pipe(
    customClaims,
    map((claims: any) => {
      const currentClaims = claims[`roles`] as { admin: string[] };

      return !!currentClaims?.admin?.length;
    }),
    map((claimed) => claimed || redirectUnauthorizedToLogin)
  );

export const approverOnly = (next: any) =>
  pipe(
    customClaims,
    map((claims: any) => {
      const currentClaims = claims[`roles`] as { approver: string[] };

      return !!currentClaims?.approver?.length;
    }),
    map((claimed) => claimed || redirectUnauthorizedToLogin)
  );

export const redirectLoggedInToHall = () => redirectVerifiedTo(['hall']);
export const redirectLoggedInToTabs = () => redirectVerifiedTo(['tabs']);
export const redirectUnauthorizedToLogin = () =>
  redirectUnverifiedTo(['/login']);

export const redirectUnverifiedTo = (redirect: any[]) =>
  pipe(
    emailVerified,
    map((verified) => verified || redirect)
  );
export const redirectVerifiedTo = (redirect: any[]) =>
  pipe(
    emailVerified,
    map((verified) => !verified || redirect)
  );

export const hasRole = (user: UserProfile, roles: string[]) => {
  for (let role of roles) {
    const currentRoles = user.roles
    if (currentRoles) {
      return Object.keys(currentRoles).indexOf(role) >= 0;
    }
  }
  return false;
};

// https://cloud.google.com/firestore/docs/manage-data/delete-data#collections
export async function deleteCollection(
  collection: CollectionReference,
  batchSize: number = 100
) {
  const theQuery = query(collection, (orderBy('__name__'), limit(batchSize)));

  return new Promise((resolve, reject) => {
    deleteQueryBatch(theQuery, resolve).catch(reject);
  });
}

async function deleteQueryBatch(query: Query, resolve: any) {
  const snapshot = await firstValueFrom(collectionSnapshots(query));

  const batchSize = snapshot.length;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = writeBatch(query.firestore);
  snapshot.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // NOTE: process.nextTick does not work, but keeping code here for visibility purposes

  // // Recurse on the next process tick, to avoid
  // // exploding the stack.
  // process.nextTick(() => {
  deleteQueryBatch(query, resolve);
  //});
}

/* based on https://copyprogramming.com/howto/typescript-distance-calculation-formula-c-geolocation-code-example#google_vignette */
export const calculateDistance = (lat1: number, lng1: number, lat2: number, lng2: number, unit: "K" | "M" | "F" | "ME" = "ME") => {
  var radianLat1 = lat1 * (Math.PI / 180);
  var radianLng1 = lng1 * (Math.PI / 180);
  var radianLat2 = lat2 * (Math.PI / 180);

  var radianLng2 = lng2 * (Math.PI / 180);
  var earth_radius = ["M", "F"].indexOf(unit) >= 0 ? 3959 : 6371;
  var diffLat = (radianLat1 - radianLat2);
  var diffLng = (radianLng1 - radianLng2);
  var sinLat = Math.sin(diffLat / 2);
  var sinLng = Math.sin(diffLng / 2);
  var a = Math.pow(sinLat, 2.0) + Math.cos(radianLat1) * Math.cos(radianLat2) * Math.pow(sinLng, 2.0);
  var distance = earth_radius * 2 * Math.asin(Math.min(1, Math.sqrt(a)));

  distance = unit == "F" ? distance * 5280 : unit == "ME" ? distance * 1000 : distance;

  return distance;
}

export const getLocaleDate = (date: Date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Use date-fns-tz to convert from UTC to a zoned time
  const zonedTime = utcToZonedTime(date, userTimeZone);

  // Create a formatted string from the zoned time
  const newDate = format(zonedTime, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: userTimeZone,
  });
  return newDate;
}


export const isInPast = (currentDate: Date, dateString: string) => {
  return dateString < getLocaleDate(currentDate);
}