import { isDevMode, NgModule } from '@angular/core';
import { AuthGuard } from '@angular/fire/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './resolver/data-resolver.service';
import {
  adminOnly,
  approverOnly,
  redirectLoggedInToHall,
  redirectUnauthorizedToLogin
} from './shared/utils';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full',
  },

  {
    path: 'tabs',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'boards/:boardId/values/:valueId/submit',
    resolve: {
      value: DataResolverService,
    },
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import('./pages/mark-submit/mark-submit.module').then(
        (m) => m.MarkSubmitPageModule
      ),
  },
  {
    path: 'register',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHall },
    loadChildren: () =>
      import('./pages/auth/registration/registration.module').then(
        (m) => m.RegistrationPageModule
      ),
  },
  {
    path: 'email/verify',
    loadChildren: () =>
      import('./pages/auth/verify-email/verify-email.module').then(
        (m) => m.VerifyEmailPageModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHall },
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'password/reset',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHall },
    loadChildren: () =>
      import('./pages/auth/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'email/verified',
    loadChildren: () =>
      import('./pages/auth/email-verified/email-verified.module').then(
        (m) => m.EmailVerifiedPageModule
      ),
  },
  {
    path: 'email/action',
    loadChildren: () =>
      import('./pages/auth/email/actions/actions.module').then(
        (m) => m.ActionsPageModule
      ),
  },
  {
    path: 'password/reset/confirm',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHall },
    loadChildren: () =>
      import(
        './pages/auth/reset-password-confirm/reset-password-confirm.module'
      ).then((m) => m.ResetPasswordConfirmPageModule),
  },
  {
    path: 'admin/submissions',
    canActivate: [AuthGuard],
    data: { authGuardPipe: approverOnly },
    loadChildren: () =>
      import('./pages/admin/submissions/submissions.module').then(
        (m) => m.SubmissionsPageModule
      ),
  },
  {
    path: 'admin/config',
    canActivate: [AuthGuard],
    data: { authGuardPipe: adminOnly },
    loadChildren: () =>
      import('./pages/admin/config/config.module').then(
        (m) => m.ConfigPageModule
      ),
  },
  {
    path: 'admin/config/tenants/:tenantId/templates/bingo/:templateId/edit',
    canActivate: [AuthGuard],
    data: { authGuardPipe: adminOnly },
    loadChildren: () =>
      import(
        './pages/admin/config/templates/template-edit/board/board-template-edit.module'
      ).then((m) => m.BoardTemplateEditPageModule),
  },
  {
    path: 'admin/config/tenants/:tenantId/templates/list/:templateId/edit',
    canActivate: [AuthGuard],
    data: { authGuardPipe: adminOnly },
    loadChildren: () =>
      import(
        './pages/admin/config/templates/template-edit/list/list-template-edit.module'
      ).then((m) => m.ListTemplateEditPageModule),
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: isDevMode(),
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
